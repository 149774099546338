@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: Roboto, sans-serif !important;
  -webkit-font-smoothing: antialiased;
}

body {
  @apply flex min-h-screen flex-col leading-normal;
}

// .fontROC    { font-family: roc-grotesk, sans-serif !important; }
.fontRoboto {
  font-family: Roboto, sans-serif !important;
}

a {
  font-family: Roboto, sans-serif !important;
  @apply font-medium text-blue-600;

  &:hover {
    @apply cursor-pointer underline;
  }

  @at-root.bg-black & {
    @apply text-white;
  }
}

select {
  @apply cursor-pointer; // Behaviour
  @apply rounded-lg; // Base style
  @apply bg-gradient-to-t from-gray-300 to-gray-200;

  &:disabled {
    @apply cursor-not-allowed; // Behaviour
    @apply from-gray-200 to-gray-100;
  }

  &:active {
    @apply from-gray-400 to-gray-300;
  }
}

main {
  @apply grow bg-white;
}

$exHF: calc(100vh - (72px + 52px));
.exHFooter {
  min-height: $exHF;
}

section {
  @apply w-full;
}
